/* src/components/PraxisDetails.css */

.praxis-details {
    background-color: #fff;
  }
  
  .praxis-details h2 {
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  
  .praxis-details p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  