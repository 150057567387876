/* Desktop styles remain unchanged */
.team-section {
  background-color: #fff;
}

/* Container for the image so we can position the overlay absolutely */
.team-image-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

/* Desktop: The semi-transparent overlay over the bottom of the image */
.team-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(216, 91, 156, 0.6); /* Pinkish overlay */
  padding: 1rem 0;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* Container for each person's text */
.person-info {
  text-align: center;
}

/* Styling for the person's name */
.person-name {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
}

/* Styling for the person's role */
.person-role {
  font-size: 0.9rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.team-section h2 {
  font-weight: 700;
  letter-spacing: 0.05em;
}

/* Mobile adjustments: for screens below 576px */
@media (max-width: 576px) {
  /* Remove absolute positioning so overlay appears below the image */
  .team-overlay {
    position: static;
    margin-top: 1rem; /* Space between image and overlay banner */
    background-color: rgba(216, 91, 156, 0.6); /* Keep the pinkish background */
    flex-direction: column;
    padding: 0.5rem;
  }
  
  /* Bring person name and role closer together on mobile */
  .person-name {
    margin-bottom: 0.0rem; /* Reduced spacing */
  }
  .person-role {
    margin-top: 0; /* Ensure no extra margin */
    margin-bottom: 0.5rem; /* Space below role */
  }
}

