/* src/components/AnfahrtSection.css */
.anfahrt-section {
    background-color: #fff;
  }
  
  .anfahrt-section h2 {
    font-weight: 700;
    letter-spacing: 0.05em;
  }
  
  .anfahrt-section p {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }
  
  .custom-btn {
    background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
  border: none !important;
  }
  
  .custom-btn:hover {
    background-color: #C2528C!important; /* Manually calculated darker shade */
  }
  
  