body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Core pink palette based on the logo */
  --color-primary: #D85B9C;   /* mid-saturated pink */
  --color-secondary: #F2AEC6; /* softer pink */
  --color-accent: #F9E6EE;    /* pale pink */
  
  /* Complementary colors */
  --color-white: #fff;
  --color-dark: #333; /* or #444, etc. */

  /* Optional: Text or link colors */
  --color-link: var(--color-primary);
  --color-text: var(--color-dark);
}

/* Example usage for headings and backgrounds */
h1, h2, h3 {
  color: var(--color-primary);
}

body {
  color: var(--color-text);
  /*background-color: var(--color-accent);*/
  
}

/* Option B: Use scroll-padding-top on html (supported by modern browsers) */
html {
  scroll-padding-top: 80px;
}



