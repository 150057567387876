/* src/components/Labors.css */

/* Footer container */
.labors-footer {
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  color: var(--color-white);
  padding: 1.5rem 0;
}

/* Logo next to the heading */
.footer-logo {
  height: 40px; /* Adjust to taste */
  width: auto;
}

/* Footer title (like "Meine assoziierten Labors") */
.footer-title {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 0.05em;
  color: var(--color-white); /* or var(--color-white), if you prefer */
}

/* Inline list styling: remove bullet points, etc. */
.list-inline-item {
  list-style: none;
}

/* Footer link styling */
.footer-link {
  color: var(--color-white);
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (max-width: 576px) {
  .labors-footer .list-inline-item {
    display: block;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
