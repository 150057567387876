/* src/components/OpeningHours.css */

.opening-hours-section {
  background-color: var(--color-white);         /* Clean white background */
    padding: 2rem;                  /* Adds generous padding */
    border-radius: 8px;             /* Rounded corners for a modern feel */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);  /* Subtle shadow for depth */
    margin-bottom: 2rem;
  }
  
  .opening-hours-section h2 {
    font-weight: 700;
    letter-spacing: 0.05em;
   
  }
  
  .opening-hours-section .list-group {
    border: none;
    margin: 0;
    padding: 0;
  }
  
  .opening-hours-section .list-group-item {
    font-size: 1rem;
    color: var(--color-dark);
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #ddd;  /* Light divider between items */
    padding: 0.75rem 1rem;
  }
  
  .opening-hours-section .list-group-item:last-child {
    border-bottom: none;
  }
  