/* src/components/HeroSection.css */
.hero-section {
    position: relative;
    background-image: url('../images/hero-background.jpeg');
    background-size: cover;
    background-position: center top; /* Moves the image so the top is fully visible */
    height: 80vh;
  }
  
  .top-right-info {
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: right;
    color: white;
    font-size: 0.9rem;
    z-index: 3;
  }

  .top-right-info a {
    color: white;
    text-decoration: none; /* Optional: remove underline */
  }
  

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    background: linear-gradient(
      rgba(0, 0, 0, 0.2),   /* Start color (lighter) */
      rgba(0, 0, 0, 0.6)    /* End color (darker) */
    );
  }
  
  .hero-section .container {
    z-index: 2;
    padding-top: 3rem;
    padding-bottom: 3rem;
    margin-top: 15%;

  }
  
  
  a.btn.btn-light {
    background-color: var(--color-primary) !important;
    color: var(--color-white) !important;
    border: none !important;
  }
  
  
  a.btn.btn-light:hover {
    background-color: #C2528C !important; /* Manually calculated darker shade */
  }
  
  @media (max-width: 576px) {
    .hero-section {
      background-position: 65% top; /* Shifts image to the right on mobile */
    }
    
    .top-right-info {
      right: 10px;
      top: 10px;
      font-size: 0.8rem;
    }
  }
  
  
  
  