/* Make the header sticky and style it with your pink theme */
.main-header {
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: var(--color-primary); /* Your pink variable */
    padding: 0.75rem 0;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Logo styling */
  .logo-img {
    height: 40px;
    width: auto;
  }
  
  .logo-text {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--color-white);
  }
  
  /* Navigation links */
  .nav-link {
    color: var(--color-white) !important;
    font-size: 1rem;
    margin-right: 1rem;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  
  .nav-link:visited,
  .nav-link:active {
    color: var(--color-white) !important;
  }
  
  .nav-link:hover {
    color: var(--color-accent) !important;
  }
  
  /* Adjust the hamburger icon if needed (Bootstrap default should suffice) */
  .navbar-toggler {
    border: none;
    outline: none;
  }
  
  /* Media Query Example: Adjust font sizes on smaller screens */
  @media (max-width: 576px) {
    .logo-text {
      font-size: 1rem;
    }
    .nav-link {
      font-size: 0.9rem;
      margin-right: 0.5rem;
    }
  }
  