/* src/components/UrlaubsFormular.css */

.vacation-section {
  background-color: #fff;
}

.vacation-section h2 {
  font-weight: 700;
  letter-spacing: 0.05em;
}

.vacation-section .lead {
  font-size: 1.1rem;
  color: #666;
}

.vacation-section .list-group-item {
  font-size: 1rem;
  color: #333;
}
